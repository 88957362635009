import React from 'react'

export default function ProfileIcon () {
  return (
    <div className='profileIcon'>
      <svg width="30" height="30" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.5 0C12.768 0 0 12.768 0 28.5C0 44.232 12.768 57 28.5 57C44.232 57 57 44.232 57 28.5C57 12.768 44.232 0 28.5 0ZM28.5 8.55C33.231 8.55 37.05 12.369 37.05 17.1C37.05 21.831 33.231 25.65 28.5 25.65C23.769 25.65 19.95 21.831 19.95 17.1C19.95 12.369 23.769 8.55 28.5 8.55ZM28.5 49.02C21.375 49.02 15.0765 45.372 11.4 39.843C11.4855 34.1715 22.8 31.065 28.5 31.065C34.1715 31.065 45.5145 34.1715 45.6 39.843C41.9235 45.372 35.625 49.02 28.5 49.02Z" fill="#C2C2C2"/>
      </svg>
    </div>
  )
}
